@media screen and (min-width: 995px) {
    
    
    /* Full-width containers */
    .asidehide {
    display: none;
    }

    .modal1{
      width:"20px";
    }
    
   
  }