@media screen and (max-width: 995px) {
    
    
    /* Full-width containers */
    .lengthx {
    width:180px
    }
    /* .lengthy {
        margin-left:600px;
        } */

    
    
   
  }

  @media screen and (min-width: 1000px) {
    
    
    /* Full-width containers */
    .lengthx {
    width:400px
    }
    

    
    
   
  }